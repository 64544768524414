import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'

const PaddingPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(6),
  '@media (max-width:1200px)': {
    padding: theme.spacing(5)
  },
  '@media (max-width:900px)': {
    padding: theme.spacing(4)
  },
  '@media (max-width:600px)': {
    padding: theme.spacing(3)
  }
}))

export default PaddingPaper
