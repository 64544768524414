import { styled } from '@mui/material/styles'
import Container from '@mui/material/Container'

const TopContainer = styled(Container)(({ theme, hero }) => ({
  display: hero && 'flex',
  flexDirection: hero && 'column',
  alignItems: hero && 'center',
  justifyContent: hero && 'center',
  minHeight: 'calc(100vh - 64px)',
  paddingTop: !hero && theme.spacing(20),
  '@media (max-width:1200px)': {
    paddingTop: !hero && theme.spacing(16)
  },
  '@media (max-width:900px)': {
    paddingTop: !hero && theme.spacing(12)
  },
  '@media (max-width: 600px) and (orientation: landscape)': {
    minHeight: 'calc(100vh - 48px)'
  },
  '@media (max-width:600px)': {
    minHeight: 'calc(100vh - 56px)',
    paddingTop: !hero && theme.spacing(9)
  }
}))

export default TopContainer
