import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'

const TitleBox = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(10),
  '@media (max-width:1200px)': {
    paddingBottom: theme.spacing(9)
  },
  '@media (max-width:900px)': {
    paddingBottom: theme.spacing(7)
  },
  '@media (max-width:600px)': {
    paddingBottom: theme.spacing(6)
  }
}))

export default TitleBox
